export const getIp = async () => {
  let res:{ ip_addr:string, ip:string };
  try {
    res = await $fetch('https://ifconfig.me/all.json');
    return res.ip_addr;
  } catch (e) {
    res = await $fetch('https://api.ipify.org?format=json');
    return res.ip;
  }
};

export const getIpLocation = () => {
  return $fetch('https://ipinfo.io', {
    headers: {
      Authorization: 'Bearer 82b690438e6a7b',
      Accept: 'application/json',
    },
  });
};
